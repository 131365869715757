.CookieDeclaration a {
  color: #09268a;
  font-weight: 500;
  text-decoration-line: underline;
}

.CookieDeclaration p,
.CookieDeclaration span {
  color: #131e29;
  font-family: var(--font-work-sans);
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 24px !important;
}
