@import './carousel.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .dropdown-scrollbar::-webkit-scrollbar {
    width: 6px;
  }
  .dropdown-scrollbar::-webkit-scrollbar-track {
    border-left: 2px solid #dadbe5;
    background: #fff;
  }
  .dropdown-scrollbar::-webkit-scrollbar-thumb {
    background: #dadbe5;
  }
}

html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}

/* mobile */
h1 {
  @apply text-3xl-ws;
}
h2 {
  @apply text-2xl;
}
h3 {
  @apply text-xl;
}
h4 {
  @apply text-lg;
}
h5 {
  @apply text-lg;
}
h6 {
  @apply text-base;
}

/* Tablet */
@media screen and (min-width: 768px) {
  h1 {
    @apply text-4xl-ws;
  }
  h2 {
    @apply text-3xl-ws;
  }
  h3 {
    @apply text-2xl;
  }
  h4 {
    @apply text-xl;
  }
  h5 {
    @apply text-lg;
  }
  h6 {
    @apply text-base;
  }
}

/* Desktop */
@media screen and (min-width: 1280px) {
  h1 {
    @apply text-5xl;
  }
  h2 {
    @apply text-2xl;
  }
  h3 {
    @apply text-xl;
  }
  h4 {
    @apply text-lg;
  }
  h5 {
    @apply text-lg;
  }
  h6 {
    @apply text-base;
  }
}

/* Safari styles */
button,
[type='button'],
[type='reset'],
[type='submit'] {
  appearance: none !important;
}
